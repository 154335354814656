export class PlanValue {
    id: number;
    validFrom: Date;
    name: string;
    description: string;
    type: string;
    value: string;
    codeName: string;
    codeValueDescription: string;
}

export default interface PlanFromOffer {
    BSZINSIND: PlanValue;
    BSSPARBSTD: PlanValue;
    BSSPARSIND: PlanValue;
    BSKAT: PlanValue;
    BSMAXBA: PlanValue;
    BSRZASAT: PlanValue;
    BSGESVBSTD: PlanValue;
    BSZINSVOR: PlanValue;
    BSMAXKAPOP: PlanValue;
    BSRISKS: PlanValue;
    BSFVAUFVER: PlanValue;
    BSKOORDK3: PlanValue;
    BSKOORDK2: PlanValue;
    BSUMLBEITR: PlanValue;
    BSKOORDK1: PlanValue;
    BSPENSALT: PlanValue;
    BSRZUAB: PlanValue;
    BSRISKKAT2: PlanValue;
    BSUER: PlanValue;
    BSRISKKAT3: PlanValue;
    BSNEBENERW: PlanValue;
    BSRISKKAT1: PlanValue;
    BSVWAG: PlanValue;
    BSVZUAB: PlanValue;
    BSZINSPROJ: PlanValue;
    BSVERZNF: PlanValue;
    BSVVPBET: PlanValue;
    BSZIPROJOF: PlanValue;
    BSINDBA: PlanValue;
    BSPLAN: PlanValue;
    BSGESCHBRR: PlanValue;
    BSFVMINALT: PlanValue;
    BSGESCHBER: PlanValue;
    BSRABAG: PlanValue;
    BSMAXBO: PlanValue;
    BSRIAG: PlanValue;
    BSDAB: PlanValue;
    BSVVPAGM: PlanValue;
    BSINDBO: PlanValue;
    BSEKMAX: PlanValue;
    BSRIS: PlanValue;
    BSLOMELD: PlanValue;
    BSZIINDOF: PlanValue;
    BSLOMIN: PlanValue;
    BSBRANCHE: PlanValue;
    BSVVP: PlanValue;
    BSAHVUER: PlanValue;
    BSTEUFONDS: PlanValue;
    BSFRVERS: PlanValue;
    BSIVBAS: PlanValue;
    BSWARTEF: PlanValue;
    BSALTRSIND: PlanValue;
    BSKOOABIND: PlanValue;
    BSTFK: PlanValue;
    BSFRRISK: PlanValue;
    BSVORSALT: PlanValue;
    BSSAMA: PlanValue;
    BSKOOABSAT: PlanValue;
    BSKOORD: PlanValue;
    BSSPARKAT3: PlanValue;
    BSSPARKAT2: PlanValue;
    BSSPARKAT1: PlanValue;
    BSEKSIND: PlanValue;
    BSBEZPLAN: PlanValue;
    BSINDBAK1: PlanValue;
    BSINDBAK2: PlanValue;
    BSINDBAK3: PlanValue;
    BSTFKPROK1: PlanValue;
    BSTFKPROK2: PlanValue;
    BSTFKPROK3: PlanValue;
}

export class CodeResponse {
    id: number;
    value: string;
    description: string;
}

export interface CodeMap {
    [key: string]: CodeResponse[];
}

export enum CODE_NAMES {
    BSTFK = 'BSTFK',
    BSWARTEF = 'BSWARTEF',
    BSMAXBA = 'BSMAXBA',
    BSKOORD = 'BSKOORD',
}
