import { Sort } from '@angular/material/sort';
import { Pageable } from '../pagination/pageable.interface';
import { IUser } from '../users/user.interface';

export interface IOffersResponse {
    content: IOffer[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    last: boolean;
    size: number;
    number: number;
    sort: Sort;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}

export interface IOffer {
    id: number;
    offerGroupId: number;
    order: number;
    name: string;
    insuranceStartDate: string;
    validityDate: string;
    offerStatus: IOfferStatus;
    company: ICompany;
    numberOfPersons: number;
    pensionFund: IPensionFund;
    checklistResponse: IChecklistResponse;
    categories: ICategories;
    categoryNamesValid: boolean;
    planEditable: boolean;
    broker: IBroker;
}
export interface IBroker {
    finmaId: string;
    title: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}
export interface ICategories {
    category1Name: string;
    category2Name: string;
    category3Name: string;
}

export interface ICompany {
    id: number;
    name: string;
    externalId: string;
    sectorId: number;
    sectorName: string;
    disabledPersons: number;
    fundedStatus: number;
    address: IAddress;
}

export interface IAddress {
    id: number;
    type: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    mobile: string;
    website: string;
    language: number;
    title: number;
    firstName: string;
    lastName: string;
    pobox: string;
    additionalAddress: string;
    additionalInformation1: string;
    additionalInformation2: string;
    ts: string;
}
export interface IPensionFund {
    id: number;
    externalId: string;
    name: string;
    foundation: IFoundation;
    pensionFundType: string;
}

export interface IFoundation {
    id: number;
    externalId: string;
    name: string;
}
export interface IChecklistResponse {
    activeRetiredRatio: boolean;
    ageGroup: boolean;
    admissionGuidelines: boolean;
    managementApproval: boolean;
}

export interface IOfferStatus {
    id: number;
    offerId: number;
    status: IStatus;
    timestamp: Date;
    owner: IUser;
    remark: string;
    reviewer: IUser;
    current: boolean;
}

export interface IStatus {
    id: number;
    name: OfferStatusType;
    deletable: boolean;
    editable: boolean;
    done: boolean;
}

export enum OfferStatusType {
    PENDING = 'PENDING',
    CALCULATED = 'CALCULATED',
    IN_REVIEW = 'IN_REVIEW',
    REVIEW_REJECTED = 'REVIEW_REJECTED',
    REVIEW_ACCEPTED = 'REVIEW_ACCEPTED',
    READY = 'READY',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
    EXPORTED = 'EXPORTED',
}

export type ColorMapping = {
    background: string;
    textColor: string;
};

export type OfferStatusColorMap = {
    [key in OfferStatusType]: ColorMapping;
};

export const offerStatusColorMapMapping: OfferStatusColorMap = {
    [OfferStatusType.PENDING]: { background: '#B3E5FC', textColor: '#23547B' },
    [OfferStatusType.CALCULATED]: { background: '#ECCFFF', textColor: '#694382' },
    [OfferStatusType.IN_REVIEW]: { background: '#FEEDAF', textColor: '#8A5340' },
    [OfferStatusType.REVIEW_REJECTED]: { background: '#FFD8B2', textColor: '#805B36' },
    [OfferStatusType.REVIEW_ACCEPTED]: { background: '#C8E6C9', textColor: '#256029' },
    [OfferStatusType.READY]: { background: '#c8d2e6', textColor: '#253a60' },
    [OfferStatusType.REJECTED]: { background: '#FFCDD2', textColor: '#C63737' },
    [OfferStatusType.ACCEPTED]: { background: '#C8E6C9', textColor: '#256029' },
    [OfferStatusType.EXPORTED]: { background: '#C8E6C9', textColor: '#256029' },
};

export interface IFilterOffers {
    offerName: string;
    ownerName: string;
    reviewerName: string;
    startDate: string;
    endDate: string;
    companyName: string;
    status: string;
}

export interface IPlanData {
    id: string;
    validFrom: Array<string>;
}

export interface IKeyFiguresConfig {
    visible: boolean;
    label: string;
}
