import { CommonModule } from '@angular/common';
import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormsModule, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { OffersService, ProductService, SnackbarService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ArrowNavigationDirective } from '../arrow-navigation.directive';
import { SaveEventName } from '@core/enums/save-event-name.enum';

@Component({
    selector: 'savings-table',
    templateUrl: './savings-table.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        CommonModule,
        MatIcon,
        MatTableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ArrowNavigationDirective,
    ],
    styleUrls: ['./savings-table.component.scss'],
})
export class SavingsTable implements OnInit, OnDestroy, OnChanges {
    errorNotificationActive = false;

    planSavingForm: FormGroup = this.fb.group({
        ageFrom: [0, [Validators.required, Validators.pattern(/^-?\d+(\.\d{5})?$/)]],
        employee: [0, [Validators.required, Validators.pattern(/^-?\d+(\.\d{5})?$/)]],
        employer: [0, [Validators.required, Validators.pattern(/^-?\d+(\.\d{5})?$/)]],
        minimum: [0, [Validators.required, Validators.pattern(/^-?\d+(\.\d{5})?$/)]],
        maximum: [0, [Validators.required, Validators.pattern(/^-?\d+(\.\d{5})?$/)]],
    });
    dataSourceCat = new MatTableDataSource<any>();
    currentValue;
    displayedColumnsCat = ['criticalValue', 'employee', 'employer', 'minimum', 'maximum', 'actions'];
    ageFromMinError = false;
    @Input()
    category: string;
    @Input()
    offerId: number;
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    formEdited = false;

    private _subscriptions: Subscription[] = [];

    @Output()
    savingcat = new EventEmitter<boolean>();
    startData = {};
    rowErrors: boolean[] = [];
    currentRowEdited = -1;
    tariffValueIds = [];
    constructor(
        private fb: FormBuilder,
        private _productService: ProductService,
        private _snackbarService: SnackbarService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        forkJoin([this._productService.getTarrifs(this.offerId, this.category)]).subscribe({
            next: (savingData) => {
                this.dataSourceCat = savingData[0][this.category];
                savingData[0][this.category].forEach((elem) => {
                    elem.employee = this.setTwoDecimals(elem.employee);
                    elem.employer = this.setTwoDecimals(elem.employer);
                    elem.minimum = this.addMinusToNumberWithDecimal(elem.minimum);
                    elem.maximum = this.setTwoDecimals(elem.maximum);
                    this.startData[elem.tariffValueId] = { ...elem };
                });
                if (savingData[0][this.category].length === 0) {
                    this.savingcat.emit(false);
                }
            },
            error: (err) => {
                console.error(err);
                if (err.error.errorCode === 30) {
                    this._snackbarService.handleOpenSnackbar(
                        this._translateService.instant('cabinet.errors.plan.tariffNotPresent', {
                            message: err.error.message,
                        }),
                        this._translateService.instant('cabinet.navigation.close'),
                        'error'
                    );
                }
            },
        });
        this.isFormEditable();
        let subs = this._productService.getUpdatedFormSubs().subscribe((value) => {
            if (value === 'SWITCH_TAB_SAVE' || value === 'NEXT_BTN_SAVE') {
                if (this.formEdited) {
                    this.tariffValueIds.forEach((id) => {
                        this.updateAgeStep(this.dataSourceCat[id], this.category);
                    });

                    this.formEdited = false;
                }
            }
        });
        this._subscriptions.push(subs);
    }
    calculateTabIndex(index: number): number {
        const tabIndex = index + 1;
        return tabIndex;
    }
    get ageFrom() {
        return this.planSavingForm.get('ageFrom');
    }

    ngOnChanges(): void {
        this.isFormEditable();
    }

    onChange(index, row) {
        this.currentRowEdited = index;
        this.tariffValueIds.push(index);
        this.formEdited = this.compareProperties(row, this.startData[row.tariffValueId]);
        if (this.formEdited) {
            this._productService.setProductDialogUpdates({
                savingContribution: { component: 'Saving Contributions', updated: true },
            });
        }
    }

    compareProperties(o1, o2) {
        for (var p = 0; p <= this.displayedColumnsCat.length; p++) {
            if (o1[this.displayedColumnsCat[p]] !== o2[this.displayedColumnsCat[p]]) {
                return true;
            }
        }
        return false;
    }
    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private minimumEarlyRetirementAgeControle = [0, [Validators.required]];
    private regularRetirementAgeControle = [0, [Validators.required]];
    private maximumRetirementAgeControle = [0, [Validators.required]];
    private maximumWefWithdrawalAgeControle = [0, [Validators.required]];
    private endOfContributionAgeControle = [0, [Validators.required]];

    pensionAgesForm: FormGroup = this.fb.group({
        minimumEarlyRetirementAge: this.minimumEarlyRetirementAgeControle,
        regularRetirementAge: this.regularRetirementAgeControle,
        maximumWefWithdrawalAge: this.maximumWefWithdrawalAgeControle,
        maximumRetirementAge: this.maximumRetirementAgeControle,
        endOfContributionAge: this.endOfContributionAgeControle,
    });

    isFormEditable() {
        if (!this.isEditable || this.isPreviewMode || !this.isPlanEditable) {
            this.planSavingForm.disable();
        } else {
            this.planSavingForm.enable();
        }
    }

    removeAgeStep(tariffId: number) {
        if (!this.isEditable || this.isPreviewMode || !this.isPlanEditable) {
            return;
        }
        this._productService.setProductDialogUpdates({
            savingContribution: { component: 'Saving Contributions', updated: true },
        });
        let req = this._productService.deleteTariffValue(tariffId);
        this.subscribeAndUpdateData(req);
        this.planSavingForm.enable();
    }

    updateAgeStep(tariffValue, category: string) {
        if (
            tariffValue.criticalValue == null ||
            tariffValue.employee == null ||
            tariffValue.employer == null ||
            tariffValue.minimum == null ||
            tariffValue.maximum == null
        ) {
            this._snackbarService.handleOpenSnackbar(
                this._translateService.instant('cabinet.notifications.errorOccured', {
                    message: this._translateService.instant('cabinet.notifications.cantBeEmpty'),
                }),
                'Close',
                'error'
            );
            return;
        }
        if (!this.isEditable || this.isPreviewMode || !this.isPlanEditable) {
            return;
        }
        // tariffValue.employee = this.setTwoDecimals(tariffValue.employee);
        // tariffValue.employer = this.setTwoDecimals(tariffValue.employer);
        // tariffValue.minimum = this.setTwoDecimals(tariffValue.minimum);
        // tariffValue.maximum = this.setTwoDecimals(tariffValue.maximum);
        let req = this._productService.updateTariffValues([{ ...tariffValue, tariffGroup: category }]);
        this.subscribeAndUpdateData(req);
        this.planSavingForm.enable();
    }
    setTwoDecimals(value) {
        return Number(value).toFixed(2);
    }
    addMinusToNumberWithDecimal(value) {
        if (value.indexOf('-') == -1) {
            value = `-${this.setTwoDecimals(value)}`;
        } else {
            value = this.setTwoDecimals(value);
        }
        return value;
    }
    handleSubmit() {
        let row = this.dataSourceCat[0];
        let req = this._productService.postTariffs(
            this.offerId,
            {
                tariffValueId: row.tariffValueId,
                externalId: row.externalId,
                validityId: row.validityId,
                validFrom: row.validFrom,
                longDesc: row.longDesc,
                template: row.template,
                interpolation: row.interpolation,
                typOfInterpolation: row.typOfInterpolation,
                behaveCurveBegin: row.behaveCurveBegin,
                behaveCurveEnd: row.behaveCurveEnd,
                behaveBetweenGroups: row.behaveBetweenGroups,
                sex: row.sex,
                criticalValue: this.planSavingForm.controls['ageFrom'].value,
                minimum: this.setTwoDecimals(this.planSavingForm.controls['minimum'].value),
                maximum: this.setTwoDecimals(this.planSavingForm.controls['maximum'].value),
                employee: this.setTwoDecimals(this.planSavingForm.controls['employee'].value),
                employer: this.setTwoDecimals(this.planSavingForm.controls['employer'].value),
                name: row.name,
                tariffGroup: this.category,
                standard: row.standard,
            },
            'BSSPARKAT' + this.category.charAt(this.category.length - 1)
        );

        this.subscribeAndUpdateData(req);
    }

    subscribeAndUpdateData(request) {
        request.subscribe({
            next: (data) => {
                this._productService.getTarrifs(this.offerId, this.category).subscribe({
                    next: (res) => {
                        res[this.category].forEach((elem) => {
                            elem.employee = this.setTwoDecimals(elem.employee);
                            elem.employer = this.setTwoDecimals(elem.employer);
                            elem.minimum = this.addMinusToNumberWithDecimal(elem.minimum);
                            elem.maximum = this.setTwoDecimals(elem.maximum);
                            this.startData[elem.tariffValueId] = { ...elem };
                        });
                        if (res[this.category].length === 0) {
                            this.savingcat.emit(false);
                        }
                        return (this.dataSourceCat = res[this.category]), (this.currentRowEdited = -1);
                    },
                    error: (err) => {
                        console.error(err);
                    },
                });
            },
            error: (err) => {
                console.error(err);
                if (err.error.errorCode === 31) {
                    this._snackbarService.handleOpenSnackbar(
                        this._translateService.instant('cabinet.errors.plan.tariffValueExists'),
                        this._translateService.instant('cabinet.navigation.close'),
                        'error'
                    );
                }
            },
        });
    }
}
