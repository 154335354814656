<div class="groupFieldsDiv">
  <h2>
    {{ "cabinet.offers.plan.calculationResults.basicPrinciples" | translate }}
  </h2>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.startOfTheContract" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.insuranceStartDate | date : "dd.MM.yyyy" }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.numberOfInsuredPersons"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.insuredCount }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.totalSumInsured" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.grossSalaries | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.totalInsuredSalarySum" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.insuredSalaries | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.totalAvailableRetirementAssets"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.savingTotal | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.ordinaryRetirementAgeWomanAndMan"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.ordinaryRetirementAgeMen }}
    </p>
  </div>
</div>
<div class="groupFieldsDiv">
  <h2>
    {{ "cabinet.offers.plan.calculationResults.costInDetails" | translate }}
  </h2>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.savingContributionBrokerAllocation"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.savingContributionsTotal | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.riskContribution" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.riskContributionsTotal | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.administrativeCosts" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.administrativeContributionsTotal | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.employeeContributionPerMonth"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.contributionsEmployeePerMonth | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.levyContributionPercentage" | translate
    }}</label>
    <p class="pCalc">
      {{ getPayAsYouGoRatePercentage() }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.levyContribution" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.payAsYouGoContributionsTotal | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.costOfLivingFundContribution" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.costsOfLivingFundsContributionsTotal | money }}
    </p>
  </div>
</div>
<div class="groupFieldsDiv">
  <h2>
    {{ "cabinet.offers.plan.calculationResults.summaryOfBenefits" | translate }}
  </h2>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.projectedRetirementAssetsWithInterest"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.projectedRetirementAssets | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.disabilityPensions" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.disabilityPensions | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.disabledPersonsChildrenPensions"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.disabilityChildsPensions | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.spousesAndPartnerPensions"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.spousePensions | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.orphanPensions" | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.orphansPensions | money }}
    </p>
  </div>
  <div class="row">
    <label class="label">{{
      "cabinet.offers.plan.calculationResults.additionalLumpSumDeathBenefit"
        | translate
    }}</label>
    <p class="pCalc">
      {{ calculations?.deathCapital | money }}
    </p>
  </div>
</div>
