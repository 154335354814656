import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyPipe } from '@core/pipes/money/money.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-calc-step-one',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TranslateModule,
    MoneyPipe,
    CommonModule,
  ],
  templateUrl: './calc-step-one.component.html',
  styleUrls: ['./calc-step-one.component.css'],
})
export class CalcStepOneComponent{
  @Input() calculations;

  getPayAsYouGoRatePercentage(): string {
    const rate = this.calculations?.payAsYouGoRate ?? 0.0;
    const percentage = rate * 100;
    return percentage.toFixed(1) + ' %';
  }
}
